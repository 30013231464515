.Project {
    font-family:"Open Sans","Helvetica",sans-serif;
  }

.back_button{
    background-color: #F2F3F5 ;
    padding-left: 15%;
}
.back_button button{
    background-color: #F2F3F5 ;
    border-style: solid;
    border-width: 1px;
    border-color: #4682B4;
    border-radius: 5px;
    padding: 10px 20px 10px 20px;
    font-size: 1.3rem;
    color:#4682B4;
    transition:0.3s;
}
.back_button button:hover{
    transform: scale(1.05);
}
  .Project_intro{
    padding-left: 15%;
    padding-right: 38%;
    padding-top: 5rem;
    height: 23rem;
    text-align: left;
    font-size: 1.3rem;
    line-height: 2.5rem;
    background-color: #F2F3F5 ;
  }

  .Project_content{
    padding-top:3rem;
    padding-left: 15%;
    padding-right: 15%;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    font-size: 1.2rem;
    line-height: 2.3rem;
  }

  .section_title{
    font-size: 2.3rem;
    line-height: 5rem;
    margin-bottom: 1rem;
    text-transform: uppercase; 
  }

  .section_subtitle{
    font-size: 1.6rem;
    line-height: 2rem;
    color: #4682B4;
  }
  .persona_sketch{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3rem;
  }
  .persona_sketch img{
    width: calc(50% - 1.5rem);
  }


  .persona_interview{
    display: flex;
    flex-direction: row;
    gap: 3rem;
  }

  .persona_interview div{
    width: calc(50% - 1.5rem);
  }

  #persona_storyboard{
    width: 90%;
    display: block;
    margin: auto;
  }

  .responsive_half{
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 4rem;
  }
  .responsive_half div{
    width: calc(50% - 2rem);
  }
  .responsive_problem{
    display: flex;
    flex-direction: column;
  }

  .responsive_wireframe{
    display: flex;
    flex-direction: row;
    align-items: start;
  }

  .responsive_wireframe img{
    width: 50%;
  }

  .responsive_img{
    width:70%;
    margin: auto;
  display: block;
  }

  .responsive_hifi{
    width:90%;
    margin: auto;
  display: block;
  }

  .responsive_url{
    color:black;
    text-decoration: underline;
    transition: 0.3s;
  }

  .responsive_url:hover{
    color:#4682B4;
  }

 .iframe{
    width: 90%;
    height: 80vh;
    display: block;
    margin: auto;
  }


  .figma_link{
    color: #4682B4;

  }

  #critiques {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .critique-section {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 16px;
    padding: 3rem;
    gap: 1rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  
  }
  
  .critique-title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .critique-content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  
  .critique-problems {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    gap: 1em;
    margin-right: 1rem;
  }
  
  .critique-solutions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1rem;
    width: 50%;
    gap: 1em;
  }
  
  .critique-problem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: rgba(199, 214, 255, 1);
    border-radius: 12px;
  
  }
  
  
  .critique-solution {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #FFF5C2;
    border-radius: 12px;
  }
  
  .critique-problem p {
    text-align: start;
    margin: 1em;
}
  
  .critique-solution p {
    text-align: start;
    margin: 1em;
  }

  .subtitle{
    margin:0
  }

  .subtasks {
    display: flex;
    flex-direction: column;
    gap:2rem;
  }
  
  .subtask {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 16px;
    padding: 2em;
    gap: 2rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  
  .subtask-title .subtitle {
    text-align: center;
  }
  
  .subtask-description {
    display: flex;
    flex-direction: row;
    justify-content: start;
    /* background-color: rgba(199, 214, 255, 1); */
    border-radius: 12px;
  }
  
  .subtask-description p {
    text-align: start;
    margin: 1em;
    font-size: 1em;
  }
  
  .subtask-expected {
    display: flex;
    flex-direction: row;
    justify-content: start;
    /* background-color: #FFF5C2; */
    border-radius: 12px;
  }
  
  .subtask-expected p {
    text-align: start;
    margin: 1em;
    font-size: 1em;
  }
  
  .subtask-results {
    display: flex;
    flex-direction: row;
    align-items: start;
  }
  
  .subtask-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33.33%;
    margin: 1em;
    border-radius: 12px;
  }
  
  .subtask-result b {
    margin-top: 1em;
  }
  
  .subtask-result p {
    margin: 1em;
  }
  
  .subtask-result-a {
    background-color: #FFF5C2;
  }
  
  .subtask-result-b {
    background-color: #FFD7D7;
  }
  
  .subtask-result-c {
    background-color: rgba(199, 214, 255, 1);
  }

  .gifs{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .gif img{
  width: 1000px;
  }

  .gif p{
    margin: 0;
  }

  .gif a{
    color:#4682B4;
}

#react{
    color:black
}
#react:hover{
    color:#4682B4
}

.Art{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:5%;
  flex-wrap: wrap;
}

.ArtText{
  
}

.ArtImage img{
  object-fit: cover;
  width: 600px;

}

